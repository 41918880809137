<template>

  <div class="main">
    <div class="search">


      <!--상태-->
      <el-select size="mini" style="width: 230px;margin-left: 5px" v-model="search.byField"
                 placeholder="제공사 리그명/본사 리그명">
        <el-option :value="managerConst.LeagueMatching.SEARCH_BY_MATCHINGNAME" label="제공사 리그명">제공사 리그명</el-option>
        <el-option :value="managerConst.LeagueMatching.SEARCH_BY_LEAGUENAME" label="본사리 그명">본사 리그명</el-option>
      </el-select>
      <el-input size="mini" style="width: 200px;margin-left: 30px" v-model="search.searchString"></el-input>
      <el-button
          size="mini" style="margin-left: 5px" @click="searchLeagueMatching" type="primary">검색
      </el-button>


    </div>

    <div class="data">
      <el-table
          :data="leagueMatchingList"
          style="width: 100%"
          max-height="1200"
          border>

        <!--번호-->
        <el-table-column
            fixed
            label="번호"
            width="100">
          <template slot-scope="scope">
            {{ scope.row.id }}
          </template>
        </el-table-column>
<!--        <el-table-column-->
<!--            fixed-->
<!--            label="종목"-->
<!--            width="100">-->
<!--          <template slot-scope="scope">-->
<!--            <span v-if="scope.row.gameType"></span>-->
<!--            {{ scope.row.gameType.typeName }}-->
<!--          </template>-->
<!--        </el-table-column>-->

        <el-table-column
            fixed
            label="구역"
            width="200">
          <template slot-scope="scope">
            {{ scope.row.areaName }}
          </template>
        </el-table-column>

        <el-table-column
            fixed
            label="제공사리그명"
            width="350">
          <template slot-scope="scope">
            {{ scope.row.matchingName }}
          </template>
        </el-table-column>

        <el-table-column
            fixed
            label="본사리그명"
            width="350">
          <template slot-scope="scope">
            <span v-if="scope.row.league"></span>
            {{ scope.row.league.leagueName }}
          </template>
        </el-table-column>
      </el-table>

    </div>

    <div class="pagePanel">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[20, 50, 100, 150, 200]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal">
      </el-pagination>
    </div>

  </div>


</template>

<script>

import OnlineUserComp from "../../components/administrator/OnlineUserComp";
import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
import {getLeagueMatchingList} from "../../network/manager/leagaueRequest";
import {manager} from "../../common/administrator/managerMixin";
import {Loading} from "element-ui";

export default {
  name: "ManagerLeagueMatching",
  mixins: [manager],
  components: {ManagerTopbarComp, TodayStatisticComp, OnlineUserComp},
  data() {
    return {
      visible: false,
      pageNum: 1,
      pageSize: 20,
      orderBy: 'id DESC',
      pageTotal: 0,
      search: {},
      leagueMatchingList: [],
    }
  },

  methods: {
    searchLeagueMatching() {
      this.pageNum = 1;
      this.getLeagueMatchingList()
    },
    getLeagueMatchingList() {
      let loadingInstance = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      getLeagueMatchingList(this.search, this.pageNum, this.pageSize, this.orderBy).then(res => {
        this.leagueMatchingList = res.data.data
        this.pageTotal = res.data.total
        loadingInstance.close()
      })
    },

    handleSizeChange(val) {
      this.pageNum = 1;
      this.pageSize = val;
      this.getLeagueMatchingList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getLeagueMatchingList();
    },
  },
  created() {
    this.getLeagueMatchingList()

  },

}
</script>

<style scoped>

</style>